// src/i18n.js
import { createI18n } from 'vue-i18n';

// Import translation files
import en from './locales/en.json';
import sv from './locales/sv.json';

// Create i18n instance with options
const i18n = createI18n({
  locale: 'sv', // Set default locale
  fallbackLocale: 'sv', // Set fallback locale
  messages: {
    en,
    sv
  }
});

export default i18n;
