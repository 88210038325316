<script>
import AmAPI from "@/config/http";
import { useToast } from 'vue-toast-notification';
export default {
  name: "FormPage",
  props: {
    visible: Boolean,
  },
  data() {
    return {
      appId: localStorage.getItem('appId'),
      uuId: this.$route.query.company_uuid,
      orderRefId: '',
      qrImageLink: '',
      isSla: false,
      qrCodeInterval: '',
      errorMessage: '',
      faqsAnswer: '',
      amApplications: {
        monthly_price: 0,
        yearly_price: 0, // Add yearly price property
        onboarding_care_price: 0,
        sla_business_price: 0,
        sla_enterprise_price: 0,
        start_fee: 0
      },
      amTiers: null,
      companyDetails: {
        company: {
          name: '',
          organization_number: '',
          invoice_email: '',
          address: '',
          city: '',
          zip_code: '',
          country: '',
          country_code: '',
        },
        user: {
          phone_number: "",
          email: "",
          name: "",
          role: "COMPANY_OWNER"
        },
        company_onboard_details: {
          uuid: '',
          onboarding_care: false,
          sla_addon: null,
          billing_cycle: "MONTHLY",
          tier: ''
        }
      },
      faqs: null,
      OnboardingModal: this.visible,
      slaModal: this.visible,
      contactModal: this.visible,
      bankModal: this.visible,
      faqsModal: this.visible,
    };
  },
  computed: {
    isYearly: {
      get() {
        return this.companyDetails.company_onboard_details.billing_cycle === 'ANNUALLY';
      },
      set(value) {
        this.companyDetails.company_onboard_details.billing_cycle = value ? 'ANNUALLY' : 'MONTHLY';
      }
    },
    selectedTier() {
      if (this.amTiers && this.companyDetails.company_onboard_details.tier) {
        return this.amTiers.find(tier => tier.id === this.companyDetails.company_onboard_details.tier);
      }
      return null;
    },
    totalInvoiceAmountExVat() {
      const toNumber = (value) => parseFloat(value ?? 0);

      let total = toNumber(this.amApplications.monthly_price) + toNumber(this.amApplications.start_fee);
      if (this.isYearly) {
        total = toNumber(this.amApplications.yearly_price) + toNumber(this.amApplications.start_fee);
      }
      if (this.companyDetails.company_onboard_details.onboarding_care) {
        total += toNumber(this.amApplications.onboarding_care_price);
      }
      if (this.companyDetails.company_onboard_details.sla_addon === 'BUSINESS') {
        total += toNumber(this.amApplications.sla_business_price);
      } else if (this.companyDetails.company_onboard_details.sla_addon === 'ENTERPRISE') {
        total += toNumber(this.amApplications.sla_enterprise_price);
      }
      if (this.selectedTier) {
        const priceAddon = toNumber(this.selectedTier.price_addon);
        total += this.isYearly ? priceAddon * 12 : priceAddon;
      }
      return total;
    },
  },
  methods: {
    openCloseFun(type) {
      if (type === 1){
        this.OnboardingModal = !this.OnboardingModal;
      } else if (type === 2) {
        this.slaModal = !this.slaModal;
      } else if (type === 3) {
        this.contactModal = !this.contactModal;
      } else if (type === 4) {
        this.bankModal = !this.bankModal;
        if (this.bankModal === false) {
          clearInterval(this.qrCodeInterval);
        }
      } else if (type === 5) {
        this.faqsModal = !this.faqsModal;
      }
    },
    async getAmApplication() {
      try {
        const response = await AmAPI.get('am/applications/?app_id=' + this.appId);
        if (response.data.results.length > 0){
         const data = response.data.results[0]
         this.amApplications.monthly_price = data.monthly_price;
         this.amApplications.yearly_price = data.yearly_price * 12; // Update yearly price
         this.amApplications.yearly_price_per_month = data.yearly_price;
         this.amApplications.onboarding_care_price = data.onboarding_care_price;
         this.amApplications.sla_business_price = data.sla_business_price;
         this.amApplications.sla_enterprise_price = data.sla_enterprise_price;
         this.amApplications.start_fee = data.start_fee;
          this.companyDetails.company_onboard_details.uuid = localStorage.getItem('company_onboard_uuid')
          this.getCompanyDetails();
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    },
    async getAmTiers() {
      try {
        const response = await AmAPI.get('am/tiers/');
        this.amTiers = response.data.results;
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    },
    async getCompanyDetails() {
      try {
        const response = await AmAPI.get('am/company/' + this.uuId + '/');
        const data = response.data;
        this.companyDetails.company.name = data.name
        this.companyDetails.company.organization_number = data.organization_number
        this.companyDetails.company.invoice_email = data.invoice_email
        this.companyDetails.company.address = data.address
        this.companyDetails.company.city = data.city
        this.companyDetails.company.zip_code = data.zip_code
        this.companyDetails.company.country = data.country
        this.companyDetails.company.country_code = data.country_code
        // this.companyDetails.company_onboard_details.uuid = this.uuId
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    },
    async getCompanyFaqs() {
      try {
        const response = await AmAPI.get('am/faqs/');
        this.faqs = response.data;
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    },
    async bankIdSignIn() {
      const toast = useToast();
      try {
        let payload = this.companyDetails;
        const response = await AmAPI.put('am/company/' + this.uuId + '/', payload);
        if (response.status === 400) {
          toast.open({
            message: this.$t('contact_support'), // Use this.$t for localization
            type: 'info',
            duration: 5000,
            position: 'top-right'
          });
        } else {
          this.getBankId();
          this.openCloseFun(4);
          this.qrCodeInterval = setInterval(() => {
            this.getBankId();
          }, 5000);
        }
      } catch (error) {
        toast.open({
          message: this.$t('contact_support'), // Use this.$t for localization
          type: 'info',
          duration: 5000,
          position: 'top-right'
        });
      }
    },
    async getBankId() {
      try {
        let onboardUuid = localStorage.getItem('company_onboard_uuid');
        const pageUrl = 'am/bankid/?company_onboard_uuid=' + onboardUuid;
        let payload = {
          "id": "1",
          }
        const response = await AmAPI.post(pageUrl, payload);
        console.log('response.data', response.data);
        this.qrImageLink = response.data.qr_image_link;
        this.orderRefId = response.data.order_ref;
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    },
    updateSlaFun() {
      if (this.isSla){
        this.companyDetails.company_onboard_details.sla_addon = 'BUSINESS';
      }
    },
    showFaqs(text) {
      this.faqsAnswer = text;
      this.openCloseFun(5);
    },
    async goToOrder(){
      try {
        this.errorMessage = ''
        let onboardUuid = localStorage.getItem('company_onboard_uuid');
        const pageUrl = 'am/bankid/?company_onboard_uuid=' + onboardUuid + '&order_ref=' + this.orderRefId;
        const response = await AmAPI.get(pageUrl);
        localStorage.setItem('purchaseInfo', JSON.stringify(response.data))
        this.openCloseFun(4);
        this.$router.push({name: 'order-detail'})
      } catch (error) {
        this.errorMessage = 'The user that the signing didn’t go through and asks the user to try again'
        console.error('Error fetching details:', error);
      }
    },
  },
  watch: {
    // Update the openClose state when the prop 'visible' changes
    visible: function(newVal) {
      this.OnboardingModal = newVal;
      this.slaModal = newVal;
      this.contactModal = newVal;
      this.bankModal = newVal;
    }
  },
  mounted() {
    this.getAmApplication();
    this.getAmTiers();
    this.getCompanyFaqs();
  }
};
</script>

<template>
  <div class="pagePadding">
    <div class="row">
      <div class="col-md-8">
        <div class="form-section">
          <form @submit.prevent="bankIdSignIn()">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="companyName">{{ $t('company_name') }} *</label>
                <input type="text" class="form-control" name="name" required id="companyName" v-model="companyDetails.company.name">
              </div>
              <div class="form-group col-md-6">
                <label for="companyOrgNumber">{{ $t('company_organisation_number') }} *</label>
                <input type="text" class="form-control" name="organization_number" required id="companyOrgNumber" v-model="companyDetails.company.organization_number">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="address">{{ $t('address') }} *</label>
                <input type="text" class="form-control" name="address" required id="address" v-model="companyDetails.company.address">
              </div>
              <div class="form-group col-md-6">
                <label for="city">{{ $t('city') }} *</label>
                <input type="text" class="form-control" name="city" required id="city" v-model="companyDetails.company.city">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="zipcode">{{ $t('zipcode') }} *</label>
                <input type="text" class="form-control" name="zip_code" required id="zipcode" v-model="companyDetails.company.zip_code">
              </div>
              <div class="form-group col-md-6">
                <label for="billingEmail">{{ $t('billing_email') }} *</label>
                <input type="email" class="form-control" name="invoice_email" required id="billingEmail" v-model="companyDetails.company.invoice_email">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="firmatecknareNamn">{{ $t('firmatecknare_namn') }} *</label>
                <input type="text" class="form-control" name="userName" required id="firmatecknareNamn" v-model="companyDetails.user.name">
              </div>
              <div class="form-group col-md-6">
                <label for="firmatecknareMobil">{{ $t('firmatecknare_mobil') }} *</label>
                <input type="text" class="form-control" name="phone_number" required id="firmatecknareMobil" v-model="companyDetails.user.phone_number">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="firmatecknareEmail">{{ $t('firmatecknare_email') }} *</label>
                <input type="email" class="form-control" name="email" required id="firmatecknareEmail" v-model="companyDetails.user.email">
              </div>
              <div class="form-group col-md-6">
                <label for="serviceRole">{{ $t('role_label') }} *</label>
                <select id="serviceRole" class="form-control" name="role" required v-model="companyDetails.user.role">
                  <option value="" disabled>{{ $t('choose') }}</option>
                  <option value="COMPANY_OWNER">{{ $t('company_owner') }}</option>
                  <option value="STAFF">{{ $t('staff') }}</option>
                  <option value="ACCOUNTANT">{{ $t('accountant') }}</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="serviceTiers">{{ $t('transaktionsvolym_per_månad') }} *</label>
                <select id="serviceTiers" class="form-control" name="tier" required v-model="companyDetails.company_onboard_details.tier">
                  <option value="" disabled>{{ $t('choose') }}</option>
                  <option v-for="(item, index) in amTiers" :key="index" :value="item.id">{{ item.title }}</option>
                </select>
              </div>
              <div class="form-group col-md-6" v-if="companyDetails.user.role === 'ACCOUNTANT'">
                <label for="ekonomikonsultNamn">{{ $t('ekonomikonsult_namn') }}</label>
                <input type="text" class="form-control" id="ekonomikonsultNamn">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6 custom-checkbox">
                <input type="checkbox" id="onboardingService" class="small-checkbox" v-model="companyDetails.company_onboard_details.onboarding_care">
                <label for="onboardingService">{{ $t('onboarding_service_label') }} <a @click.prevent="openCloseFun(1)" class="question-mark">?</a></label>
              </div>
              <div class="form-group col-md-6" v-if="companyDetails.user.role === 'ACCOUNTANT'">
                <label for="ekonomikonsultEpost">{{ $t('ekonomikonsult_epost') }}</label>
                <input type="email" class="form-control" id="ekonomikonsultEpost">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6 custom-checkbox">
                <input type="checkbox" id="purchaseSLA" class="small-checkbox" v-model="isSla" @change="updateSlaFun">
                <label for="purchaseSLA">{{ $t('purchase_sla_label') }} <a @click.prevent="openCloseFun(2)" class="question-mark">?</a></label>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6" v-if="isSla">
                <select class="form-control" v-model="companyDetails.company_onboard_details.sla_addon">
                  <option value="" disabled>{{ $t('choose') }}</option>
                  <option value="BUSINESS" selected>{{ $t('sla_business') }}</option>
                  <option value="ENTERPRISE">{{ $t('sla_enterprise') }}</option>
                </select>
              </div>
            </div>
            <div class="form-group custom-checkbox">
              <input type="checkbox" id="yearlyContract" class="small-checkbox" v-model="isYearly">
              <label for="yearlyContract">{{ $t('yearly_contract_label') }} <a @click.prevent="openCloseFun(3)" class="question-mark">?</a></label>
            </div>
            <div class="text-right proceedBtnSpace">
              <button class="proceedButton" type="submit">{{ $t('proceed_to_bankid') }}</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <div class="pricing-card">
          <ul>
            <li v-if="!isYearly">{{ $t('connector_price_monthly') }}<span>{{ amApplications.monthly_price }}:-</span></li>
            <li v-if="isYearly">{{ $t('connector_price_yearly') }}<span>{{ amApplications.yearly_price }}:- ({{ amApplications.yearly_price_per_month }}/ month)</span></li>
            <li>{{ $t('license_fee') }}<span>{{ amApplications.start_fee }}:-</span></li>
            <li v-if="companyDetails.company_onboard_details.onboarding_care">{{ $t('onboarding_care_fee') }}<span>{{ amApplications.onboarding_care_price }}:-</span></li>
            <li v-if="companyDetails.company_onboard_details.sla_addon === 'BUSINESS'">{{ $t('sla_business') }}<span>{{ amApplications.sla_business_price }}:-</span></li>
            <li v-if="companyDetails.company_onboard_details.sla_addon === 'ENTERPRISE'">{{ $t('sla_enterprise') }}<span>{{ amApplications.sla_enterprise_price }}:-</span></li>
            <li v-if="selectedTier">
              <span v-if="isYearly">{{ $t('tier_price_addon_yearly') }}<span>{{ (selectedTier.price_addon ?? 0) * 12 }}:- ({{ selectedTier.price_addon ?? 0 }}/ month)</span></span>
              <span v-else>{{ $t('tier_price_addon_monthly') }}<span>{{ selectedTier.price_addon ?? 0 }}:-</span></span>
            </li>
            <li>{{ $t('total_invoice_amount') }}<span>{{ totalInvoiceAmountExVat }}:-</span></li>
          </ul>
        </div>
        <div class="faq-section mt-5">
          <h5>{{ $t('frequently_asked_questions') }}</h5>
          <ul>
            <li class="mt-3" style="cursor: pointer" v-for="(item, index) in faqs" :key="index" @click.prevent="showFaqs(item.answer)">{{ item.question }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal start -->
  <!-- SLA package Modal -->
  <div class="modal fade show d-block" v-if="slaModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" @click.prevent="openCloseFun(2)">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class="modal-title text-center">{{ $t('what_is_included_in_sla_package') }}</h3>
          <div class="d-flex justify-content-end flex-md-row flex-column-reverse">
            <div>
              <h6 class="mt-5 font-weight-bold">{{ $t('business_sla') }}</h6>
              <p><strong>{{ $t('connector_configuration') }}</strong></p>
              <p>{{ $t('connector_configuration_desc') }}</p>
              <p><strong>{{ $t('coordination_with_your_accountant') }}</strong></p>
              <p>{{ $t('coordination_with_your_accountant_desc') }}</p>
              <p><strong>{{ $t('general_advice') }}</strong></p>
              <p>{{ $t('general_advice_desc') }}</p>
            </div>
            <div><img class="modalImg" src="../assets/images/modal.png" alt=""></div>
          </div>

          <h6 class="mt-5 font-weight-bold">{{ $t('enterprise_sla') }}</h6>
          <p><strong>{{ $t('connector_configuration') }}</strong></p>
          <p>{{ $t('connector_configuration_desc') }}</p>
          <p><strong>{{ $t('coordination_with_your_accountant') }}</strong></p>
          <p>{{ $t('coordination_with_your_accountant_desc') }}</p>
          <p><strong>{{ $t('general_advice') }}</strong></p>
          <p>{{ $t('general_advice_desc') }}</p>

          <h3 class="slaHeading">{{ $t('sla_heading') }}</h3>
          <p class="mt-5"><strong>{{ $t('reduce_startup_costs') }}</strong></p>
          <p>{{ $t('reduce_startup_costs_desc') }}</p>
          <p><strong>{{ $t('reduce_stress') }}</strong></p>
          <p>{{ $t('reduce_stress_desc') }}</p>
          <p><strong>{{ $t('faster_start') }}</strong></p>
          <p>{{ $t('faster_start_desc') }}</p>

          <h3 class="headingSla">{{ $t('what_is_not_included_in_sla_package') }}</h3>
          <p class="mt-5"><strong>{{ $t('fortnox_setup') }}</strong></p>
          <p>{{ $t('fortnox_setup_desc') }}</p>
          <p><strong>{{ $t('accounting_advice') }}</strong></p>
          <p>{{ $t('accounting_advice_desc') }}</p>
          <p><strong>{{ $t('third_party_system_setups') }}</strong></p>
          <p>{{ $t('third_party_system_setups_desc') }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- onboardingCare Modal -->
  <div class="modal fade show d-block" v-if="OnboardingModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close">
            <span @click.prevent="openCloseFun(1)">&times;</span>
          </button>
        </div>
        <div class="modal-body container">
          <h3 class="modal-title text-center">{{ $t('what_is_included_in_onboarding_care') }}</h3>
          <div class="d-flex justify-content-end flex-md-row flex-column-reverse">
            <div>
              <p class="mt-5"><strong>{{ $t('connector_configuration') }}</strong></p>
              <p>{{ $t('connector_configuration_desc') }}</p>
              <p><strong>{{ $t('coordination_with_your_accountant') }}</strong></p>
              <p>{{ $t('coordination_with_your_accountant_desc') }}</p>
              <p><strong>{{ $t('general_advice') }}</strong></p>
              <p>{{ $t('general_advice_desc') }}</p>
            </div>
            <div><img src="../assets/images/modal.png" class="modalImg" alt=""></div>
          </div>

          <h3 class="slaHeading">{{ $t('why_purchase_onboarding_care') }}</h3>
          <p class="mt-5"><strong>{{ $t('reduce_startup_costs') }}</strong></p>
          <p>{{ $t('reduce_startup_costs_desc') }}</p>
          <p><strong>{{ $t('reduce_stress') }}</strong></p>
          <p>{{ $t('reduce_stress_desc') }}</p>
          <p><strong>{{ $t('faster_start') }}</strong></p>
          <p>{{ $t('faster_start_desc') }}</p>

          <h3 class="headingSla">{{ $t('what_is_not_included_in_onboarding_care') }}</h3>
          <p class="mt-5"><strong>{{ $t('fortnox_setup') }}</strong></p>
          <p>{{ $t('fortnox_setup_desc') }}</p>
          <p><strong>{{ $t('accounting_advice') }}</strong></p>
          <p>{{ $t('accounting_advice_desc') }}</p>
          <p><strong>{{ $t('third_party_system_setups') }}</strong></p>
          <p>{{ $t('third_party_system_setups_desc') }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Yearly contact Modal -->
  <div class="modal fade show d-block" v-if="contactModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" @click.prevent="openCloseFun(3)">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class="modal-title text-center">{{ $t('what_does_yearly_contract_mean') }}</h3>
          <div class="d-flex justify-content-end flex-md-row flex-column-reverse">
            <div>
              <p><strong>{{ $t('connector_configuration') }}</strong></p>
              <p>{{ $t('connector_configuration_desc') }}</p>
              <p><strong>{{ $t('coordination_with_your_accountant') }}</strong></p>
              <p>{{ $t('coordination_with_your_accountant_desc') }}</p>
              <p><strong>{{ $t('general_advice') }}</strong></p>
              <p>{{ $t('general_advice_desc') }}</p>
            </div>
            <div><img src="../assets/images/modal.png" class="modalImg" alt=""></div>
          </div>
          <h3 class="slaHeading">{{ $t('why_go_with_yearly_contract') }}</h3>
          <p class="mt-5"><strong>{{ $t('reduce_startup_costs') }}</strong></p>
          <p>{{ $t('reduce_startup_costs_desc') }}</p>
          <p><strong>{{ $t('reduce_stress') }}</strong></p>
          <p>{{ $t('reduce_stress_desc') }}</p>
          <p><strong>{{ $t('faster_start') }}</strong></p>
          <p>{{ $t('faster_start_desc') }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- bankId Modal -->
  <div class="modal fade show d-block" v-if="bankModal">
    <div class="modal-dialog modal-md">
      <div class="modal-content overflow-hidden">
        <div class="modal-header">
          <button type="button" class="close">
            <span aria-hidden="true" @click="openCloseFun(4)">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="text-center bankText">{{ $t('bankid_text') }}</div>
        </div>
        <div class="text-center">
          <img :src="qrImageLink" class="barcode">
        </div>
        <div class="bankModalBtn">
          <button class="styled-button" @click="goToOrder">{{ $t('complete_signing') }}</button>
        </div>
        <div class="text-center">
          <span class="text-danger">{{ errorMessage }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade show d-block" v-if="faqsModal">
    <div class="modal-dialog modal-md">
      <div class="modal-content overflow-hidden">
        <div class="modal-header">
          <button type="button" class="close">
            <span aria-hidden="true" @click="openCloseFun(5)">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0 mb-5">
          <p class="text-center">{{ faqsAnswer }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
