<script>
import { mapGetters } from 'vuex';

export default {
  name: "order-detail",
  data() {
    return {
      loginUrl: '',
      apps: [],
    }
  },
  methods: {
    goToHome(url) {
      window.location.href = url;
    },
    setPageData() {
      const purchaseInfo = JSON.parse(localStorage.getItem('purchaseInfo'))
      this.loginUrl = purchaseInfo.login_link;
      this.apps = purchaseInfo.recommended_apps;
    },
    getLogoUrl(logo) {
      if (logo.startsWith('http')) {
        return logo;
      }
      return `http://${logo}`;
    }
  },
  mounted() {
    this.setPageData();
  },
  computed: {
    ...mapGetters(['currentLanguage'])
  }
}
</script>

<template>
  <div class="confirmation-section container">
    <img src="../assets/images/tick.jpeg" class="tickImg" alt="Checkmark">
    <p>{{ $t('thank_you_for_order') }}<br>
      {{ $t('email_confirmation') }}<br><br>
      {{ $t('purchased_onboarding_care') }}</p>
    <button class="btn" @click="goToHome(loginUrl)">{{ $t('continue_to_login') }}</button>
  </div>

  <div class="connectors-section">
    <h2>{{ $t('complementary_connectors') }}</h2>
    <div class="container">
      <div class="row bottomSpace">
        <div class="col-md-3 mb-4" v-for="(item, index) in apps" :key="index">
          <div class="card">
            <div class="connector-img-container">
              <img :src="getLogoUrl(item.logo_url)" v-if="item.logo_url" class="connector-img" :alt="item.app_name">
              <img src="../assets/images/AM-logo.png" v-else class="connector-img" :alt="item.app_name">
            </div>
            <div class="card-body">
              <h5 class="card-title" style="height: 72px">{{ item.app_name }}</h5>
              <p class="card-text">{{ item.short_description || '' }}</p>
            </div>
          </div>
          <div class="button mt-4 text-center">
            <span class="btn" @click="goToHome(item.fortnox_activation_url)">{{ $t('activate_app') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.row {
  background-color: #ffffff;
}
.card {
  height: 350px; /* Fixed height for the card */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.connector-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Fixed height for the image container */
}
.connector-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
