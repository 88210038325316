import { createStore } from 'vuex';

export default createStore({
  state: {
    locale: 'en'
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    }
  },
  actions: {},
  getters: {}
});
