import { createRouter, createWebHistory } from 'vue-router'
import Detail from "@/components/detail.vue";
import OrderDetail from "@/components/order-detail.vue";
import Form from "@/components/form.vue";
import NotFound from "@/components/not-found.vue";

const routes = [
    {
        name: 'DetailComponent',
        path: '/:id',
        component: Detail,
        meta: {
            requiresAuth: false,
            title: '',
        },
    },
    {
        name: 'order-detail',
        path: '/order',
        component: OrderDetail,
        meta: {
            requiresAuth: false,
            title: '',
        },
    },
    {
        name: 'FormPage',
        path: '/form',
        component: Form,
        meta: {
            requiresAuth: false,
            title: '',
        },
    },
    {
        name: 'not-found',
        path: '/not-found',
        component: NotFound,
        meta: {
            requiresAuth: false,
            title: '',
        },
    },
    {
        path: '/',
        redirect: { name: 'not-found' },
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
