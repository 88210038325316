import axios from 'axios';


export const baseURL = process.env.VUE_APP_ROOT_API;
const AmAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

AmAPI.interceptors.request.use(
    config => {
        const token = `${localStorage.getItem('token')?.replace(/['"]+/g, '')}`;

        if (config.headers && token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

AmAPI.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const status = error?.response?.status;
        const responseStatus = error?.response?.data?.code;
        const redirectOnInternalErr = error?.response?.config?.headers?.['_client_internal_err_redirect'] !== false;
        console.log('status', error);
        if (status === 403 || status === 401 || responseStatus === 401) {
            console.log("Logging out due to status:", status);
            setTimeout(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location = '/';

            }, 1000);

        }

        if (status === 412) {
            // window.location.href = '/not-found';
        }

        if (status === 500 && redirectOnInternalErr) {
            // window.location.href = '/server-error';
        }

        return Promise.reject(error);
    }
);

export default AmAPI;