<template>
  <header class="header d-flex justify-content-end">
    <nav class="nav">
      <a class="nav-link mRight" href="#" @click="toggleLanguage">{{ languageButtonText }}</a>
      <a class="nav-link loginMargin" href="https://portal.automatiseramera.se">{{ $t('login') }}</a>
    </nav>
  </header>

  <div class="main-content">
    <h3>{{ $t('process') }}</h3>
    <div class="dots">
      <div class="dot dot-purple"></div>
      <div class="dot dot-pink"></div>
      <div class="dot dot-cyan"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  methods: {
    goNotFound() {
      this.$router.push({ name: 'not-found' });
    },
    toggleLanguage() {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'sv' : 'en';
    }
  },
  computed: {
    languageButtonText() {
      return this.$i18n.locale === 'en' ? 'SVENSKA' : 'ENGLISH';
    }
  }
}
</script>

<style scoped>

</style>
