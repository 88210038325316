<template>
  <div>
    <Header/>
    <router-view/>
 <Footer/>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/header.vue";
export default {
  name: 'App',
  mounted() {
    document.title = "Automatisera Mera - Onboard";
  },
  components: {
    Header,
    Footer
  }
}
</script>


<style>
body {
  font-family: Arial, sans-serif;
  background-color: #f7f8fa;
  padding: 0;
}
</style>
