<script>
export default {
  name: "not-found",
  methods: {
    goHome() {
      this.$router.push({ name: 'home' });
    }
  }
}
</script>

<template>
  <div class="bottomSpaces">
    <div class="center-container">
      <img src="../assets/images/notFound.png" :alt="$t('page_not_found')">
    </div>
    <div class="text-center">
      <button class="styled-button" @click="goHome">{{ $t('back_to_home') }}</button>
    </div>
  </div>
</template>

<style scoped>
.bottomSpaces {
  margin-bottom: 200px;
}
.center-container {
  display: flex;
  justify-content: center;
}
.center-container img {
  max-width: 913px;
  height: auto;
}
</style>
