import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/header.css';
import './assets/css/detail.css';
import './assets/css/form.css';
import './assets/css/order-detail.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import './assets/css/main.css';
import router from "@/router";
import i18n from './i18n';
import store from './store'; // Import the store
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css'; // You can choose different themes

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.use(VueToast, {
  position: 'top-right', // Set the default position to top-right
  duration: 5000,
  dismissible: true
});
app.mount('#app');
