<script>
import AmAPI from '../config/http';
export default {
  name: "DetailComponent",
  data() {
    return {
      appId: this.$route.params.id,
      appDetail: null,
    };
  },
  methods: {
    goToForm(uuid) {
      this.$router.push({ name: 'FormPage', params: { id: this.appId, uuid: uuid } });
    },
    async login() {
      console.log('this.email', this.email);
      localStorage.clear()
      try {
        if(localStorage.getItem('token')){
          this.fetchDetails();
          return
        }
        let payload = {
          email: process.env.VUE_APP_EMAIL,
          password: process.env.VUE_APP_PASSWORD
        }
        const response = await AmAPI.post('api/token/', payload);
        localStorage.setItem('token', response.data.access)
        this.fetchDetails()
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    },
    async fetchDetails() {
      try {
        const response = await AmAPI.get('am/connect/' + this.appId + '/');
        this.appDetail = response.data
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    },
    async proceedWithAuth() {
      try {
        const response = await AmAPI.get('am/proceed/' + this.appId + '/');
        localStorage.setItem('appId', this.appId);
        localStorage.setItem('company_onboard_uuid', response.data.company_onboard_uuid);
        window.location.href = response.data.url
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    }
  },
  mounted() {
    this.login();
  }
}
</script>

<template>
  <div class="container detailMargin">
    <div class="d-flex justify-content-between flex-column flex-md-row">
      <!-- CARD 1 -->
      <div class="detailCard shadow-sm">
        <div class="card-body">
          <img src="../assets/images/block.png" class="blockImg" alt="block">
          <h6 class="card-titles ">{{ $t('purchase_app_title') }}</h6>
          <p class="card-texts">{{ $t('purchase_app_text') }}</p>
        </div>
      </div>

      <!-- CARD 2 -->
      <div class="detailCard shadow-sm">
        <div class="card-body">
          <img src="../assets/images/setting-icon.png" class="blockImg" alt="block">
          <h6 class="card-titles">{{ $t('configure_activate_title') }}</h6>
          <p class="card-texts">{{ $t('configure_activate_text') }}</p>
        </div>
      </div>

      <!-- CARD 3 -->
      <div class="detailCard shadow-sm">
        <div class="card-body">
          <img src="../assets/images/glasses.png" class="glassesImg" alt="block">
          <h6 class="card-titles">{{ $t('follow_up_title') }}</h6>
          <p class="card-texts">{{ $t('follow_up_text') }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Strip PLUS -->
  <div class="container stripe d-flex justify-content-between flex-column flex-md-row">
    <div class="text mt-2">{{ $t('note_proceed') }}</div> 
    <div class="stripeHead ml-3">{{ appDetail?.app_name }}</div> 
    <div class="text mt-2">{{ $t('sys_admin_permission') }}</div>
  </div>

  <!-- Button -->
  <div class="container text-center btnMargin">
    <button class="styled-button" @click="proceedWithAuth()">{{ $t('proceed_button') }}</button>
  </div>
</template>

<style scoped>

</style>
