<template>
  <footer class="footer">
    <div class="container">
      <p v-html="$t('footer_text')"></p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  bottom: 0;
  width: 100%;
  background-color: #E7E4F8;
  padding: 90px 0px 60px 60px;
  text-align: left;
  color: #828181a3;
}
p {
  font-size: 10px;
  color: #828181a3;
}
</style>

<script>
export default {
  name: 'FooterPage'
}
</script>
